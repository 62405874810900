<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col cols="12"><strong>{{ $t('common.menu.account-book.profit-and-loss') }}</strong></b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form inline class="mb-2">
                            <font-awesome-icon
                                role="button"
                                icon="chevron-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="chevron-right"
                                @click="nextMonth()"
                            />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-tabs lazy content-class="mt-3">
                            <b-tab v-for="(tab,index) in tabs"
                                   :key="tab.key"
                                   v-if="tab.enabled"
                                   :active="activeTab==tab.key"
                            >
                                <template slot="title">
                            <span :class="tab.class">
                            <font-awesome-icon :icon="tab.icon"/>
                            {{ tab.title }}
                            </span>
                                </template>
                                <component v-bind:is="tab.tab" :month="month"></component>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>


            </CCardBody>
        </CCard>
    </div>
</template>

<script>
const TabGeneral = () => import('./profit_and_loss/_general')
const TabDebit = () => import('./profit_and_loss/_debit')
const TabCredit = () => import('./profit_and_loss/_credit')

import moment from 'moment'
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import commonJobsSave from '@/bundles/erika_common_bundle/mixins/common-save'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'ProfitAndLoss',
    mixins: [commonTable, tableDateFilter, commonJobsSave],
    mounted() {
    },
    data() {
        return {
            month: moment().format('MM.YYYY'),
            dateStart: null,
            dateEnd: null,
            tableOptions: {
                sortBy: 'created_at',
            },
            saveFilters: true,
            activeTab: 'general',
            tabs: [
                {
                    'key': 'general',
                    'title': this.$t('profit_and_loss.tab.general'),
                    'icon': 'info',
                    'tab': TabGeneral,
                    'enabled': true,
                },
                {
                    'key': 'credit',
                    'title': this.$t('profit_and_loss.tab.credit'),
                    'icon': 'plus',
                    'tab': TabCredit,
                    'enabled': true,
                    'class': 'text-success',
                },
                {
                    'key': 'debit',
                    'title': this.$t('profit_and_loss.tab.debit'),
                    'icon': 'minus',
                    'tab': TabDebit,
                    'enabled': true,
                    'class': 'text-danger',
                }
            ]
        }
    },
    methods: {
        ...mapGetters('Sales', ['getSalesTable']),
        getActions() {
            return []
        },
    },
}
</script>